<template>
  <component is="b-card">
    <b-tabs pills>
	  <b-tab active @click="orderSummaryClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Orders summary") }}</span>
        </template>
        <order-summary-tab
          ref="orderSummaryRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab active @click="inPreparationClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("In preparation") }}</span>
        </template>
        <order-in-preparation-tab
          ref="inPreparationRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="readyClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Ready") }}</span>
        </template>
        <order-ready-tab
          ref="readyRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="readyInStockClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Ready in stock") }}</span>
        </template>
        <order-ready-in-stock-tab
          ref="readyInStockRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <!----<b-tab @click="readyToDeliveryClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Ready to delivery") }}</span>
        </template>
        <order-ready-to-delivery-tab
          ref="ReadyToDeliveryRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>-->
      <b-tab @click="returnClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Return") }}</span>
        </template>
        <order-return-tab
          ref="returnRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OrderSummaryTab from "./OrderSummaryTab.vue";
import OrderInPreparationTab from "./OrderInPreparationTab.vue";
import OrderReadyTab from "./OrderReadyTab.vue";
import OrderReadyInStockTab from "./OrderReadyInStockTab.vue";
import OrderReadyToDeliveryTab from "./OrderReadyToDeliveryTab.vue";
import OrderReturnTab from "./OrderReturnTab.vue";
import instance from "@/libs/axios";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
	OrderSummaryTab,
    OrderInPreparationTab,
    OrderReadyTab,
    OrderReadyInStockTab,
    OrderReturnTab,
    OrderReadyToDeliveryTab,
  },
  data() {
    return {
      subProfiles: [],
      emergencyDegrees: [],
      clients: [],
    };
  },
  methods: {
    orderSummaryClicked() {
      this.$refs["orderSummaryRef"].refreshTable();
    },
	inPreparationClicked() {
      this.$refs["inPreparationRef"].refreshTable();
    },
    nonConfirmClicked() {
      this.$refs["nonConfirmRef"].refreshTable();
    },
    readyClicked() {
      this.$refs["readyRef"].refreshTable();
    },
    readyInStockClicked() {
      this.$refs["readyInStockRef"].refreshTable();
    },
    readyToDeliveryClicked() {
      this.$refs["ReadyToDeliveryRef"].refreshTable();
    },
    returnClicked() {
      this.$refs["returnRef"].refreshTable();
    },
  },
  async mounted() {
    try {
      const res = await Promise.all([
        instance.get("/parameters/subprofiles/"),
        instance.get("/parameters/emergency-degrees/"),
        instance.get("/parameters/clients/"),
      ]);
      this.subProfiles = res[0].data;
      this.emergencyDegrees = res[1].data;
      this.clients = res[2].data.results;
    } catch (err) {
      const error = err.response
        ? Object.values(err.response.data)[0][0]
        : err.message;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: error ?? this.$t("An error has occurred. Please try again"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
};
</script>

<style></style>
