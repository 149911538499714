<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-card no-body class="mb-0">
      <b-table
        id="scrolle-table"
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(photo)="data">
          <a :href="'https://gc.azul-fish.com/'+ data.item.product_combination.photo" :data-fancybox="data.item.product_combination.product_name.name" :data-caption="data.item.product_combination.product_name.name">
            <b-img
                :src="'https://gc.azul-fish.com/'+ data.item.product_combination.photo"
                alt="photo"
                class="image-width"
            />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BInputGroup,
  BCardText,
  BBadge,
  BImg, 
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useOrderSummaryTrackingList from "./useOrderSummaryTrackingList";
import Ripple from "vue-ripple-directive";
import ConsulteOrder from "./ConsulteOrder.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
Fancybox.bind("[data-fancybox]", {
    protect: true,
    Thumbs: {
        type: "classic",
    },
});
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    ConsulteOrder,
    BInputGroup,
    BImg, 
  },
  props: {
    clients: Array,
    emergencyDegrees: Array,
    subProfiles: Array,
  },
  data() {
    return {
      currentDate: null,
      deliveryTypes: [],
      Unity: "",
      ref: null,
      emergency_degree: null,
      selectedSubProfile: null,
      selectedClient: null,
      dateDefault: null,
      selectedEmergencyDegree: null,
      timeoutId: null,
      clientsRes: [],
      despoQuantity: null,
      unvailable_qty: null,
      unavailable_piece: null,
      orderline: null,
      despoPiece: null,
      emergencyDegreesRes: [],
      productCombinations: [],
      selected_combinaison_name: null,
      selectedProductCombination: null,
      selectedShippingDate: null,
      selectedShippingType: null,
      fields: [
        { key: "ref", label: this.$t("ref") },
        { key: "client_name", label: this.$t("Client") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
        { key: "product", label: this.$t("product") },
		{ key: "preparations", label: this.$t("preparation") },
        { key: "ordered_qty", label: this.$t("ordered qty") },
        { key: "piece", label: this.$t("ordered piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "actions", label: this.$t("actions") },
      ],
      clientAnnexe : [],
      selectedClientAnnexe : null,
      shipping_type_id:null,
    };
  },
  created() {
    this.currentDate = new Date().toJSON().slice(0, 10);
  },
  methods: {
    refreshTable() {
      this.refetchData();
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    } = useOrderSummaryTrackingList();

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir]
  .table.b-table.b-table-selectable:not(.b-table-selectable-no-click)
  > tbody
  > tr {
  height: 62px;
  cursor: pointer;
}
footer#orderbyline___BV_modal_footer_ {
  margin-top: -26px;
}
.image-width{
    width:60px;
}
</style>
